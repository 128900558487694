<template>
  <v-dialog
      v-model="value"
      content-class="elevation-0"
      :max-width="maxWidth"
      persistent>
    <v-card elevation="5">
      <v-card-title>
        Actividades Económicas
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <FiltrosSticky icono="mdi-filter-settings" class="mb-10">
              <v-row justify="center">
                <v-col cols="12">
                  <v-text-field
                      v-model="search"
                      append-icon="mdi-magnify"
                      label="Buscar Giro/Rubro/Código"
                      single-line
                      hide-details
                      outlined
                  ></v-text-field>
                </v-col>
              </v-row>
            </FiltrosSticky>
          </v-col>

        </v-row>
        <v-row>
          <v-col cols="12">

            <v-data-table
                :headers="headers"
                :search="search"
                :items="listado_giros"
                item-key="nombre"
                sort-by="nombre"
                group-by="nombre_padre"
                class="elevation-1"
                show-group-by
            >

            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn
            color="primary"
            @click="$emit('input', false)">
          Cerrar
        </v-btn>

      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import ProgresiveLoadAutocompleteOld from "@/components/utils/ProgresiveLoadAutocompleteOld.vue";
import FiltrosSticky from "@/views/CmReporteria/components/FiltrosSticky.vue";

export default {
  name: 'ListadoActividadPadre',
  components: {FiltrosSticky, ProgresiveLoadAutocompleteOld},
  props: {
    value: Boolean,
    maxWidth: {
      type: Number,
      default: 1200,
    },
  },
  data: () => ({
    busqueda_completa: false,
    search: null,
    id_act: null,
    select: {
      listadoActividadPadre: [],
    },
    listado_giros: [],
    headers: [
      {
        text: 'Código',
        align: 'start',
        value: 'codigo',
        groupable: false,
      },
      {
        text: 'Rubro',
        align: 'start',
        value: 'nombre',
        groupable: false,
      },
      { text: 'Detalle', value: 'nombre_padre', align: 'right' },
    ],
  }),
  methods: {
    cargarTodoCatalogo(bandera) {
      if (bandera) {
        console.log('entro', bandera);
        this.select.id_act = null;
        this.obtenerGirosRubros(true);
      } else {
        this.obtenerGirosRubros();
      }
    },
    async llenarActividades() {
      const {data} = await this.services.ActividadesServices.getActividad({actividad: true});
      this.select.listadoActividadPadre = data;
    },
    async obtenerGirosRubros() {

      const {data} = await this.services.ActividadesServices.listarGirosRubros();
      const transformedArray = data.flatMap(parent =>
          parent.hijos.map(child => ({
            ...child,
            id_padre: parent.id_padre,
            // nombre_padre: parent.nombre,
            nombre_padre: parent.ae.nombre !== 'Giro' ? `${parent.ae.nombre.toUpperCase()} - ${parent.nombre}` : parent.nombre,
            id_tipo_actividad_padre: parent.id_tipo_actividad
          }))
      );
      this.listado_giros = transformedArray

    },
  },
  created() {
    this.obtenerGirosRubros();
    this.llenarActividades();
  }
}
</script>

<style scoped>
.v-card--reveal {
  bottom: 0;
  opacity: 1 !important;
  position: absolute;
  width: 100%;
}
</style>